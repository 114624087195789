<template>
  <ModalBase
    :name="$options.name"
    :title="compTitle"
    modalContainerClass="max-w-4xl bg-white"
    :bodyPadding="false"
    overlayClose
    @close="closeHaul"
  >
    <template #body>
      <div v-if="!compHaul._id" class="container-padding relative flex justify-center">
        <UtilLoader loadingText="Loading haul" :isLoading="true" size="medium" />
      </div>

      <template v-else>
        <UtilHaulageDetails :haul="compHaul" bleedMapImage class="pb-8" />
      </template>
    </template>
  </ModalBase>
</template>

<script>
import Api from '@/lib/api'
import ModalBase from '@/components/modals/ModalBase'
import UtilHaulageDetails from '@/components/utils/UtilHaulageDetails.vue'
import UtilLoader from '@/components/utils/UtilLoader.vue'

export default {
  name: 'ModalHaul',
  components: {
    ModalBase,
    UtilHaulageDetails,
    UtilLoader
  },
  props: {
    modalData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      haulApi: new Api()
    }
  },
  created() {
    this.getHaul()
  },
  computed: {
    compHaul() {
      return this.haulApi.data || {}
    },

    compTitle() {
      return 'No title available'
    }
  },
  methods: {
    getHaul() {
      this.$router.replace({ query: { ...this.$route.query, haul: this.modalData.id } }).catch(() => {})

      this.haulApi.get(`/v2/crm/hauls/${this.modalData.id}`)
    },

    closeHaul() {
      let query = Object.assign({}, this.$route.query)
      delete query.haul

      this.$router.replace({ query }).catch(() => {})
    }
  }
}
</script>
