<template>
  <div>
    <slot v-if="isLoading && $slots.skeleton" name="skeleton" />

    <div
      v-else-if="isLoading && !$slots.skeleton"
      class="flex items-center"
      :class="{ 'text-6xl': size === 'large', 'text-4xl': size === 'medium' }"
    >
      <FontAwesomeIcon :icon="faSpinnerThird" spin fixedWidth class="mr-4" />

      <p v-if="loadingText && !longLoadingText">{{ loadingText }}</p>

      <p v-if="longLoadingText && showLongLoadingText" class="loading-text" :class="longLoadingTextClass">
        {{ longLoadingText }}
      </p>
    </div>

    <slot v-else></slot>

    <template v-if="$slots.timeout && isConsideredTimeout">
      <slot name="timeout" />
    </template>
  </div>
</template>

<script>
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import dayjs from 'dayjs'

export default {
  name: 'UtilLoader',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    loadingText: String,
    size: {
      type: String,
      default: 'small',
      validator: prop => ['small', 'medium', 'large'].includes(prop)
    },
    showLongLoadingText: {
      type: Boolean,
      default: true
    },
    longLoadingTextClass: String
  },
  data() {
    return {
      timeStart: 0,
      timer: null,
      longLoadingText: null,
      isConsideredTimeout: false,
      faSpinnerThird
    }
  },
  watch: {
    isLoading(loading) {
      if (loading) {
        this.startSlowLoadingTimer()
      } else {
        this.stopSlowLoadingTimer()
      }
    }
  },
  mounted() {
    if (this.isLoading) {
      this.startSlowLoadingTimer()
    }
  },
  destroyed() {
    this.stopSlowLoadingTimer()
  },
  methods: {
    startSlowLoadingTimer() {
      if (!this.timer) {
        this.timeStart = dayjs()

        this.timer = setInterval(() => {
          let timeNow = dayjs()

          const secondsTimeDifference = timeNow.diff(this.timeStart, 'second')

          if (secondsTimeDifference > 5) {
            this.longLoadingText = 'Still loading...'
          }

          if (secondsTimeDifference > 15) {
            this.longLoadingText = 'Internet connectivity issues?'
          }

          if (secondsTimeDifference > 25) {
            this.longLoadingText = 'This is taking too long, try reloading the app or contact us to investigate.'
            this.isConsideredTimeout = true
          }
        }, 1000)
      }
    },

    stopSlowLoadingTimer() {
      clearInterval(this.timer)
      this.longLoadingText = null
    }
  }
}
</script>
